<template>
  <div>
    <div v-if="!userManagementInstructionsRead && users.length > 1" class="p-2_5 pt-0 pb-0">
      <UserManagementInstructions/>
    </div>
    <div class="overview_tools d-flex align-items-center">
      <div v-if="checkPermission('core.organization.departments') || checkPermission('core.organization.teams') || checkPermission('core.organization.employees') || checkPermission('core.organization.import') || checkPermission('core.organization.export')" class="mt-1 mt-xl-0 d-inline-block align-top">
        <CDropdown color="primary" stoggler-text="" :caret="false" class="mr-2 table_actions_dropdown">
          <div slot="toggler-content">
            <span class="d-flex align-items-center">
              {{$t('Add')}}<i class="fas fa-caret-down ml-1"></i>
            </span>
          </div>

          <CDropdownItem v-if="checkPermission('core.organization.departments')">
            <div class="d-flex align-items-center" @click="openSidebarRight('department', { department_id: null });">
              <span>{{$t('common.Department')}}</span>
            </div>
          </CDropdownItem>
          <CDropdownItem v-if="checkPermission('core.organization.teams')">
            <div class="d-flex align-items-center" @click="openSidebarRight('team', { team_id: null, department_id: null, department_name: null })">
              <span>{{$t('common.Team')}}</span>
            </div>
          </CDropdownItem>          
          <CDropdownItem v-if="checkPermission('core.organization.employees')" :disabled="!seatsAvailable">
            <div class="d-flex align-items-center" @click="openSidebarRight('user', { user_id_external: null, team: null});">
              <span>{{$t('common.Employee')}}</span>
            </div>
          </CDropdownItem>
        </CDropdown>
        
        <CButton v-if="checkPermission('core.organization.import')" class="mr-2 link" color="primary" @click="showImportPage();">
          <i class="fas fa-file-arrow-up mr-1"/><span>{{ $t('users.Import_users') }}</span>
        </CButton>
        <CButton v-if="checkPermission('core.organization.export')" class="mr-2 link" color="primary" @click="exportUsers();">
          <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('users.Export_users') }}</span>
        </CButton>
      </div>
      <b-switch class="m-0" v-model="userFilters.show_unemployed" size="is-small" @input="filterUsers()">{{ $t('users.Show_unemployed') }}</b-switch>
    </div>

    <div class="user_filters">
      <CRow class="m-0">
        <CCol cols="4" lg="4" class="pt-0 pb-0 search">
          <label><b>{{$t('users.Search_within_employee_details')}}</b></label>
          <CInput type="text" class="mb-0 searchbar" v-model="userFilters.search_term" v-debounce:1s="filterUsers"></CInput>
        </CCol>
        <CCol cols="4" lg="4" class="pt-0 pb-0">
          <label><b>{{$t('common.Filter_on_department')}}</b></label>
          <multiselect class="data_table open_absolute"
                        v-model="selectedDepartments" 
                        :options="departments" 
                        :multiple="true"                
                        :placeholder="$t('common.Select_department')"
                        :selectLabel="$t('common.Add_department')" 
                        :selectedLabel="$t('Added')"
                        :deselectLabel="$t('common.Remove_department')"
                        track-by="department_id" 
                        label="department_name"
                        @search-change="asyncFindDepartment"
                        @input="filterUsers()">
            <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
            <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
          </multiselect>
        </CCol>
        <CCol cols="4" lg="4" class="pt-0 pb-0">
          <label><b>{{$t('common.Filter_on_team')}}</b></label>
          <multiselect class="data_table open_absolute"
                        v-model="selectedTeams" 
                        :options="teams" 
                        :multiple="true"                
                        :placeholder="$t('common.Select_team')" 
                        :selectLabel="$t('common.Add_team')" 
                        :selectedLabel="$t('Added')"
                        :deselectLabel="$t('common.Remove_team')"
                        track-by="team_id" 
                        label="team_name"
                        @search-change="asyncFindTeam"
                        @input="filterUsers()">
            <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
            <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
          </multiselect>
        </CCol>        
      </CRow>
    </div>
    <hr class="mt-2_5" v-bind:class="{'mb-2_5' : users.length > 0, 'mb-0' : users.length == 0}">
    <div class="data_table_container">
      <CRow v-if="usersLoading === true">
        <CCol cols="12" lg="12">
          <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
        </CCol>
      </CRow>      
      <CRow v-else>
        <CCol cols="12" sm="12" md="12" lg="12" v-bind:class="{'pt-0 pb-0' : users.length > 0}">
          <div v-if="users.length > 0">
            <b-table class="data_table includes_dropdown"
                     ref="userTable"
                     :data="users"
                     :striped="true"
                     :hoverable="true"
                     :mobile-cards="true"
                     :current-page.sync="currentPage"
                     backend-pagination
                     :paginated="isPaginated"
                     :total="totalItems"
                     :per-page="perPage"
                     :pagination-simple="isPaginationSimple"
                     :pagination-position="paginationPosition"
                     @page-change="onPageChange"
                     backend-sorting
                     :default-sort="[sortField, sortOrder]"
                     :default-sort-direction="defaultSortDirection"
                     :sort-icon="sortIcon"
                     :sort-icon-size="sortIconSize"                   
                     @sort="onTableSort">
                    
              <template slot-scope="props">
                <b-table-column field="image" label="" :searchable="false" :sortable="false" width="2.5%">
                  <div v-if="props.row.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                  <div v-else class="profile_icon">
                    <userProfileAvatar :username="props.row.fullname" :size="25"/>
                  </div>
                </b-table-column>
                <b-table-column field="fullname" :label="$t('common.Name')" :searchable="searchEnabled" :sortable="true">
                  <span @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external })" class="pointer">{{props.row.fullname}}</span>
                  <div class="item_tags d-flex flex-column">
                    <div class="management_labels" v-if="props.row.team_manager">
                      <b-tag v-for="team in props.row.teams_managed_by_user" v-bind:key="team.team_id" class="mt-1 info">{{team.team_name}}</b-tag>
                    </div>
                    <div class="management_labels" v-if="props.row.department_manager">
                      <b-tag v-for="department in props.row.departments_managed_by_user" v-bind:key="department.department_id" class="mt-1 info">{{department.department_name}}</b-tag>
                    </div>
                    <div>
                      <b-tag v-if="props.row.platform_role_tags.includes('admin')" class="mt-1 fit-content detail">{{ $t('common.Admin') }}</b-tag>
                      <b-tag v-if="props.row.left_organization" class="mt-1 fit-content left_organization">{{ $t('common.Left_organization') }}</b-tag>
                      <b-tag v-if="!props.row.is_active" class="mt-1 fit-content inactive">{{ $t('common.Inactive') }}</b-tag>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column field="user_function" :label="$t('common.Function')" :searchable="searchEnabled" :sortable="true">
                  {{props.row.user_function !== null ? props.row.user_function : '-' }}
                </b-table-column>                    
                <b-table-column field="department_name" :label="$t('common.Department')" :searchable="searchEnabled" :sortable="true">
                  {{props.row.department_name !== null ? props.row.department_name : '-' }}
                </b-table-column>
                <b-table-column field="team_name" :label="$t('common.Team')" :searchable="searchEnabled" :sortable="true">
                  {{props.row.team_name !== null ? props.row.team_name : '-' }}
                </b-table-column>
                <b-table-column field="user_managers" :label="$t('common.Executives')" :searchable="searchEnabled" :sortable="false">
                  <div v-if="props.row.user_managers.length > 0" class="user_managers d-flex align-items-center">
                    <userProfilePopover ref="userProfilePopover"
                                        v-for="manager in props.row.user_managers"
                                        v-bind:key="manager.user_id_external"
                                        mode="user"
                                        :userName="manager.name"
                                        :userProfileImage="manager.profile_image"
                                        :userIdExternal="manager.user_id_external"
                                        :senderIdExternal="null"
                                        popoverPlacement="right"/>
                  </div>
                  <span v-else>-</span>
                </b-table-column>
                <b-table-column field="date_started" :label="$t('common.Date_started')" :sortable="true">
                  <span v-if="props.row.date_started !== null">{{props.row.date_started | moment("DD-MM-YYYY")}}</span>
                  <span v-else>-</span>
                </b-table-column>
                <b-table-column field="date_left" :visible="userFilters.show_unemployed" :label="$t('common.Date_left')" :sortable="true">
                  <span v-if="props.row.date_left !== null">{{props.row.date_left | moment("DD-MM-YYYY")}}</span>
                  <span v-else>-</span>
                </b-table-column>
                <b-table-column field="work_location" :label="$t('users.Work_location')" :searchable="searchEnabled" :sortable="true">
                  {{props.row.work_location !== null ? props.row.work_location : '-' }}
                </b-table-column>              
                <b-table-column field="last_app_activity" :label="$t('common.Last_activity')" :sortable="true">
                  <span v-if="props.row.last_app_activity !== null">{{props.row.last_app_activity | moment("DD-MM-YYYY HH:ss")}}</span>
                  <span v-else>-</span>                    
                </b-table-column>                  
                <b-table-column field="actions">
                  <div class="d-flex justify-content-lg-end">
                    <CDropdown color="primary" stoggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                      <div slot="toggler-content">
                        <span class="d-flex align-items-center">
                          {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                        </span>
                      </div>
                                            
                      <CDropdownItem v-if="checkRole('super_admin') && checkDateLeft(props.row.date_left)" class="admin">
                        <div class="d-flex align-items-center" @click="openSidebarRight('reset_employee_password', { user_id_external:  props.row.user_id_external, employee_name: props.row.fullname })">
                          <div class="post_action_icon text-center mr-1">
                            <i class="fas fa-lock"/>
                          </div>
                          <div class="flex-grow-1">
                            <span>{{$t('admin.Reset_employee_password')}}</span>
                          </div>
                        </div>
                      </CDropdownItem>
                      <CDropdownItem v-if="props.row.is_active">
                        <div class="d-flex align-items-center" @click="deactivateUser(props.row.user_id_external)">
                          <div class="post_action_icon text-center mr-1">
                            <i class="fa-solid fa-ban"></i>
                          </div>
                          <div class="flex-grow-1">
                            <span>{{$t('organisation.Deactivate_user')}}</span>
                          </div>
                        </div>
                      </CDropdownItem>
                      <CDropdownItem v-if="props.row.is_active">
                        <div class="d-flex align-items-center" @click="employeeLeftModal = true; employeeLeftModalData = props.row;">
                          <div class="post_action_icon text-center mr-1">
                            <i class="fa-solid fa-door-open"></i>
                          </div>
                          <div class="flex-grow-1">
                            <span>{{$t('users.Employee_left_organization')}}</span>
                          </div>
                        </div>
                      </CDropdownItem>                                           
                      <CDropdownItem>
                        <div class="d-flex align-items-center" @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external })">
                          <div class="post_action_icon text-center mr-1">
                            <i class="fas fa-user"/>
                          </div>
                          <div class="flex-grow-1">
                            <span>{{$t('common.Show_profile')}}</span>
                          </div>
                        </div>
                      </CDropdownItem>
                      <CDropdownItem v-if="checkPermission('core.organization.employees')">
                        <div class="d-flex align-items-center" @click="openSidebarRight('user', { user_id_external: props.row.user_id_external })">
                          <div class="post_action_icon text-center mr-1">
                            <i class="fas fa-pen"/>
                          </div>
                          <div class="flex-grow-1">
                            <span>{{$t('users.Edit_employee_details')}}</span>
                          </div>
                        </div>
                      </CDropdownItem>                                                                
                    </CDropdown>
                  </div>
                </b-table-column>
              </template>                
            </b-table>          
          </div>
          <div v-else class="text-center">
            <span v-if="userFilters.search_term || selectedDepartments.length > 0 || selectedTeams.length > 0">{{$t('common.No_employees_based_on_filters')}}</span>
            <span v-else>{{$t('common.no_employees_found')}}</span>
          </div>
        </CCol>
      </CRow>
    </div>

    <b-modal :can-cancel="['x']" :active.sync="passwordResetLinkModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('organisation.Reset_password')}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="m-0">{{ $t('organisation.Are_you_sure_to_send_reset_link') }} {{passwordResetLinkModalData.fullname}}?</p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="sendPasswordResetLink(passwordResetLinkModalData.user_id_external); passwordResetLinkModal = false;"><i class="fas fa-check mr-1"/>{{$t('organisation.Send_reset_link')}}</CButton>
          <CButton color="secondary" @click="passwordResetLinkModal = false;"><i class="fas fa-times mr-1"/>{{$t('organisation.Dont_send_reset_link')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal :can-cancel="['x']" :active.sync="verificationEmailModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('organisation.Send_welcome_email')}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="m-0">{{ $t('organisation.Are_you_sure_to_send_welcome_email') }} {{verificationEmailModalData.fullname}}?</p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="sendVerificationEmail(verificationEmailModalData.user_id_external); verificationEmailModal = false;"><i class="fas fa-check mr-1"/>{{$t('organisation.Send_welcome_email')}}</CButton>
          <CButton color="secondary" @click="verificationEmailModal = false;"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal :can-cancel="['x']" :active.sync="employeeLeftModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('users.Set_date_left_for_employee', { employee_name : employeeLeftModalData.name})}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="mb-2_5">{{$t('users.Select_date_left_for_employee')}}</p>
              <b-datepicker v-model="employeeDateLeft" :placeholder="$t('common.click_to_select')" inline editable :datepicker="datepickerOptions">
                <template #left>
                  <CButton color="primary" @click="employeeDateLeft = new Date();"><i class="fas fa-clock mr-1"/>{{$t('Now')}}</CButton>
                </template>
                <template #right>
                  <CButton color="secondary" @click="employeeDateLeft = null;"><i class="fas fa-times mr-1"/>{{$t('Clear')}}</CButton>
                </template>                    
              </b-datepicker>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="updateUserDateLeft(employeeLeftModalData.user_id_external);" :disabled="!employeeDateLeft">
            <i class="fas fa-check mr-1"/>{{$t('Save')}}
          </CButton>
          <CButton color="secondary" @click="employeeLeftModal = false">
            <i class="fas fa-times mr-1"/>{{ $t('cancel') }}
          </CButton>
        </CCardFooter>
      </CCard>
    </b-modal>         
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

import UserManagementInstructions from '@/components/core/UserManagementInstructions.vue';

export default {
  name: 'Users',
  props: ['platformRoles', 'platformPermissions', 'seatsAvailable'],
  components: {
    Multiselect,
    loadingSpinner,
    userProfilePopover,
    userProfileAvatar,
    UserManagementInstructions
  },
  data: () => {
    return {
      apiBaseUrl: null,
      clientToken: null,      
      users: [],
      usersLoading: false,
      usersLoaded: false,      
      userFilters: {
        search_term: '',
        show_unemployed: false,
        departments: [],
        teams: []        
      },
      selectedDepartments: [],
      selectedTeams: [],
      departments: [],
      teams: [],
      sortField: 'fullname',
      sortOrder: 'asc',
      defaultSortDirection: 'asc',
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,      
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: false,
      totalItems: 0,
      usersStartIndex: 0,
      userManagementInstructionsRead: false,
      passwordResetLinkModal: false,
      passwordResetLinkModalData: {},
      verificationEmailModal: false,
      verificationEmailModalData: {},
      employeeLeftModal: false,
      employeeLeftModalData: {},
      employeeDateLeft: null,
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      }      
    }
  },
  methods: {   
    getUsers() {
      // Start the loader
      if(this.usersLoaded === false) this.usersLoading = true;
      // Set the params
      let params = {};
      params.userFilters = this.userFilters;
      params.userFilters.sort_field = this.sortField;
      params.userFilters.sort_order = this.sortOrder;
      params.userFilters.departments = [];
      params.userFilters.teams = [];

      for(let d = 0; d < this.selectedDepartments.length; d++) {
        params.userFilters.departments.push(this.selectedDepartments[d].department_id);
      }

      for(let t = 0; t < this.selectedTeams.length; t++) {
        params.userFilters.teams.push(this.selectedTeams[t].team_id);
      }

      // Get the users
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/users/overview/' + this.usersStartIndex, params)
      .then(res => {
        // Clear the users array
        this.users = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_users;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the users to the users array
        this.users = res.data.data.users;
        // Check if the pagination should be activated
        (currentTotal > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.usersLoading = false;
        // Update the usersLoaded value
        this.usersLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getUserDetails(userIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/' + userIdExternal)
      .then(res => {
        let updatedUser = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.userTable !== undefined ) {

          let userIndex = this.$refs.userTable.data.findIndex(x => x.user_id_external == updatedUser.user_id_external);

          this.$refs.userTable.data[userIndex].platform_roles = updatedUser.platform_roles;
          this.$refs.userTable.data[userIndex].platform_role_tags = updatedUser.platform_role_tags;
          this.$refs.userTable.data[userIndex].fullname = updatedUser.fullname;
          this.$refs.userTable.data[userIndex].team_name = updatedUser.team_name;
          this.$refs.userTable.data[userIndex].department_name = updatedUser.department_name;
          this.$refs.userTable.data[userIndex].user_function = updatedUser.user_function;
          this.$refs.userTable.data[userIndex].date_started = updatedUser.date_started;
          this.$refs.userTable.data[userIndex].date_left = updatedUser.date_left;
          this.$refs.userTable.data[userIndex].managers = updatedUser.managers;
          this.$refs.userTable.data[userIndex].active = updatedUser.active;
          this.$refs.userTable.data[userIndex].hours_on_contract = updatedUser.hours_on_contract;
          this.$refs.userTable.data[userIndex].meyer_briggs = updatedUser.meyer_briggs;
          this.$refs.userTable.data[userIndex].disc = updatedUser.disc;
          this.$refs.userTable.data[userIndex].office_based = updatedUser.office_based;
          this.$refs.userTable.data[userIndex].division = updatedUser.division;
          this.$refs.userTable.data[userIndex].language = updatedUser.language;
          this.$refs.userTable.data[userIndex].persona_1 = updatedUser.persona_1;
          this.$refs.userTable.data[userIndex].persona_2 = updatedUser.persona_2;
          this.$refs.userTable.data[userIndex].persona_3 = updatedUser.persona_3;
          this.$refs.userTable.data[userIndex].work_location = updatedUser.work_location;          
        }       
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    showPasswordResetLinkModal(data) {
      this.passwordResetLinkModal = true;
      this.passwordResetLinkModalData = data;
    },     
    sendPasswordResetLink(userIdExternal) {
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/resetpassword/user/' + userIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.Password_reset_link_sent'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    showVerificationEmailModal(data) {
      this.verificationEmailModal = true;
      this.verificationEmailModalData = data;
    },
    sendVerificationEmail(userIdExternal) {
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/verifiction/email/user/' + userIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('organisation.Welcome_email_sent'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },      
    deactivateUser(userIdExternal) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/user/' + userIdExternal + '/deactivate')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.User_deactivated'), type: 'is-success', duration: 2000 });
        // Update the users
        this.getUsers();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    updateUserDateLeft(userIdExternal) {
      let params = {};
      params.date_left = this.$luxon(this.employeeDateLeft.toISOString(), "yyyy-MM-dd");

      axios.put(process.env.VUE_APP_API_URL + '/v1/core/user/' + userIdExternal + '/left-company', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.User_updated'), type: 'is-success', duration: 2000 });
        // Update the users
        this.getUsers();
        // Close the modal
        this.employeeLeftModal = false;
        // Reset the employeeDateLeft value
        this.employeeDateLeft = null;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },           
    exportUsers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/users/export', {responseType: 'blob'})
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.Export_succesful'), type: 'is-success', duration: 2000 });
        if (!window.navigator.msSaveOrOpenBlob){
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'employees-export.csv');
          document.body.appendChild(link);
          link.click();
        }else{
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob(new Blob([res.data]),"employees-export.csv");
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    onPageChange(page) {
      // Set the usersStartIndex value
      (page > 1) ? this.usersStartIndex = (page - 1)*10 : this.usersStartIndex = 0;
      // Get the rewards
      this.getUsers();
    },   
    onTableSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // Reset the pagination;
      this.resetPagination();
      // Get the users
      this.getUsers()
    },
    filterUsers() {
      // Reset the pagination;
      this.resetPagination();
      // Get the users
      this.getUsers();
    },    
    resetPagination() {
      this.currentPage = 1;
      this.usersStartIndex = 0;
    },   
    showImportPage() {
      this.$router.push({path: '/core/user-management/import'});
    },
    asyncFindDepartment (query) {
      let searchTerm = query;
      this.departments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.departments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam (query) {
      let searchTerm = query;
      this.teams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.teams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    checkDateLeft(dateLeft) {      
      if(!dateLeft || (dateLeft && (Date.parse(new Date()) <= Date.parse(dateLeft)))) {
        return true;
      } else{
        return false;
      }
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.getUsers();

    if(localStorage.getItem('userManagementInstructionsRead') !== null) {
      // Update the userManagementInstructionsRead value if necessary
      if(localStorage.getItem('userManagementInstructionsRead') === 'Y') this.userManagementInstructionsRead = true;
    }

    this.$bus.$on('update_users', () => {
      this.getUsers();
    });

    this.$bus.$on('update_user_details', (userIdExternal) => {
      this.getUserDetails(userIdExternal);
    });
    
    this.$bus.$on('user_management_instructions_read', (e) => {
      this.userManagementInstructionsRead = true
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_users');
    this.$bus.$off('update_user_details');
    this.$bus.$off('user_management_instructions_read');    
  }  
}
</script>
