<template>
  <div>
    <div class="overview_tools d-flex align-items-center">
      <div v-if="checkPermission('core.organization.departments') || checkPermission('core.organization.teams') || checkPermission('core.organization.employees') || checkPermission('core.organization.import') || checkPermission('core.organization.export')" class="mt-1 mt-xl-0 d-inline-block align-top">        
        <CDropdown color="primary" stoggler-text="" :caret="false" class="mr-2 table_actions_dropdown">
          <div slot="toggler-content">
            <span class="d-flex align-items-center">
              {{$t('Add')}}<i class="fas fa-caret-down ml-1"></i>
            </span>
          </div>

          <CDropdownItem v-if="checkPermission('core.organization.departments')">
            <div class="d-flex align-items-center" @click="openSidebarRight('department', { department_id: null });">
              <span>{{$t('common.Department')}}</span>
            </div>
          </CDropdownItem>
          <CDropdownItem v-if="checkPermission('core.organization.teams')">
            <div class="d-flex align-items-center" @click="openSidebarRight('team', { team_id: null, department_id: null, department_name: null })">
              <span>{{$t('common.Team')}}</span>
            </div>
          </CDropdownItem>          
          <CDropdownItem v-if="checkPermission('core.organization.employees')" :disabled="!seatsAvailable">
            <div class="d-flex align-items-center" @click="openSidebarRight('user', { user_id_external: null, team: null});">
              <span>{{$t('common.Employee')}}</span>
            </div>
          </CDropdownItem>
        </CDropdown>
                
        <CButton v-if="checkPermission('core.organization.import')" class="mr-2 link" color="primary" @click="showImportPage();">
          <i class="fas fa-file-arrow-up mr-1"/><span>{{ $t('users.Import_users') }}</span>
        </CButton>
        <CButton v-if="checkPermission('core.organization.export')" class="mr-2 link" color="primary" @click="exportUsers();">
          <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('users.Export_users') }}</span>
        </CButton>
      </div>      
      <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showUnemployed" size="is-small" @input="filterOrganisationData()">{{ $t('users.Show_unemployed') }}</b-switch>
      <b-switch class="mb-0 mt-1 mt-xl-0" v-model="employeeFocus" size="is-small">{{ $t('organisation.Focus_on_employees') }}</b-switch>
    </div>
    <hr class="mt-0 mb-2_5">
    <div>
      <CRow class="departments m-0">
        <CCol v-if="!employeeFocus" cols="4" lg="4" class="p-0">
          <div>
            <CRow class="m-0 actions">
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                <span class="text-uppercase">
                  <b>{{$t('common.Departments')}}</b> 
                </span>
              </CCol>
              <!-- <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                <div v-if="checkPermission('core.organization.departments')" class="mt-1 mt-xl-0 d-inline-block align-top">
                  <CButton class="m-0" color="primary" @click="openSidebarRight('department', { department_id: null });">
                    <i class="fas fa-plus mr-1"/>
                    <span>{{ $t('organisation.Add_department') }}</span>
                  </CButton>
                </div>
              </CCol> -->
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !departmentsLoading}">
                <div v-if="departmentsLoading">
                  <loadingSpinner mode="auto" v-if="departmentsLoading" :content="$t('common.Loading')"/>                            
                </div>
                <div v-else>                    
                  <b-table ref="departmentTable"
                          class="data_table"
                          :data="departments"
                          :striped="true"
                          :hoverable="true"
                          :mobile-cards="true"
                          :paginated="departmentsPaginated"
                          :per-page="departmentsPerPage"
                          :current-page.sync="departmentsPage"
                          :pagination-simple="isPaginationSimple"
                          :pagination-position="paginationPosition"
                          :default-sort-direction="defaultSortDirection"
                          :sort-icon="sortIcon"
                          :sort-icon-size="sortIconSize"
                          :row-class="(row, index) => row.department_id === activeDepartment.department_id && 'active'">
                        
                    <template slot-scope="props">                      
                      <b-table-column field="department_name" :label="$t('organisation.Department_name')" :searchable="searchEnabled" width="60%">
                        <span class="pointer" @click="getDepartmentTeams(props.row.department_name, props.row.department_id);">
                          <b>{{props.row.department_name}}</b>
                        </span>
                        <span class="d-block meta">
                          <span>{{props.row.nr_of_teams}} {{props.row.nr_of_teams !== 1 ? $t('common.teams') : $t('common.team')}}</span>                          
                          <span v-if="showUnemployed"> | {{props.row.nr_of_employees}} {{$t('organisation.employees')}}</span>
                          <span v-else> | {{props.row.nr_of_employed_employees}} {{$t('organisation.employees')}}</span>
                        </span>
                      </b-table-column>
                      <b-table-column field="department_managers" :label="$t('organisation.Department_managers')">
                        <div v-if="props.row.department_managers.length > 0" class="user_managers d-flex align-items-center">
                          <userProfilePopover ref="userProfilePopover"
                                              v-for="manager in props.row.department_managers"
                                              v-bind:key="manager.user_id_external"
                                              mode="user"
                                              :userName="manager.name"
                                              :userProfileImage="manager.profile_image"
                                              :userIdExternal="manager.user_id_external"
                                              :senderIdExternal="null"
                                              popoverPlacement="right"/>
                        </div>
                        <span v-else>-</span>                        
                      </b-table-column>
                      <b-table-column field="actions" :visible="checkPermission('core.organization.departments')">
                        <div class="d-flex justify-content-lg-end">
                          <CButton class="m-0" color="primary" @click="openSidebarRight('department', { department_id: props.row.department_id })">
                            <i class="fas fa-pen"/>
                          </CButton>
                        </div>
                      </b-table-column>                                            
                    </template>
                    <template slot="empty">
                      <div class="text-center">
                        <span>{{$t('organisation.No_departments_found')}}</span>
                      </div>                
                    </template> 
                  </b-table>
                </div>            
              </CCol>
            </CRow>
          </div>
        </CCol>

        <CCol v-if="!employeeFocus" cols="4" lg="4" class="p-0">
          <div>
            <CRow class="m-0 actions">
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">                    
                <span v-if="activeDepartment.department_id" v-line-clamp="1" class="text-uppercase">
                  <b>{{activeDepartment.department_name}}</b>
                </span>
                <span v-else class="text-uppercase"><b>{{$t('common.Teams')}}</b></span>
              </CCol>
              <!-- <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                <div v-if="checkPermission('core.organization.teams') && activeDepartment.department_id" class="mt-1 mt-xl-0 d-inline-block align-top">
                  <CButton class="m-0"
                           color="primary"
                           @click="openSidebarRight('team', { team_id: null, department_id: activeDepartment.department_id, department_name: activeDepartment.department_name })">                    
                    <i class="fas fa-plus mr-1"/>
                    <span>{{ $t('organisation.Add_team') }}</span>
                  </CButton>
                </div>
              </CCol> -->
            </CRow>
            <CRow v-if="activeDepartment.department_id">
              <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !teamsLoading}">
                <div v-if="teamsLoading">
                  <loadingSpinner mode="auto" v-if="teamsLoading" :content="$t('common.Loading')"/>                            
                </div>
                <div v-else>                    
                  <b-table ref="teamsTable"
                          class="data_table includes_dropdown"
                          :data="teams"
                          :striped="true"
                          :hoverable="true"
                          :mobile-cards="true"
                          :paginated="teamsPaginated"
                          :per-page="teamsPerPage"
                          :current-page.sync="teamsPage"
                          :pagination-simple="isPaginationSimple"
                          :pagination-position="paginationPosition"
                          :default-sort-direction="defaultSortDirection"
                          :sort-icon="sortIcon"
                          :sort-icon-size="sortIconSize"
                          :row-class="(row, index) => row.team_id === activeTeam.team_id && 'active'">
                        
                    <template slot-scope="props">                              
                      <b-table-column field="team_name" :label="$t('organisation.Team_name')" :searchable="searchEnabled" width="60%">
                        <span class="pointer" @click="getTeamEmployees(props.row.team_name, props.row.team_id);">
                          <b>{{props.row.team_name}}</b>
                        </span>
                        <span class="d-block meta">
                          <span v-if="showUnemployed">{{props.row.nr_of_employees}} {{$t('organisation.employees')}}</span>
                          <span v-else>{{props.row.nr_of_employed_employees}} {{$t('organisation.employees')}}</span>
                        </span>                      
                      </b-table-column>
                      <b-table-column field="team_managers" :label="$t('organisation.Team_managers')">
                        <div v-if="props.row.team_managers.length > 0" class="user_managers d-flex align-items-center">
                          <userProfilePopover ref="userProfilePopover"
                                              v-for="manager in props.row.team_managers"
                                              v-bind:key="manager.user_id_external"
                                              mode="user"
                                              :userName="manager.name"
                                              :userProfileImage="manager.profile_image"
                                              :userIdExternal="manager.user_id_external"
                                              :senderIdExternal="null"
                                              popoverPlacement="right"/>
                        </div>
                        <span v-else>-</span>                        
                      </b-table-column>                      
                      <b-table-column field="actions">
                        <div class="d-flex justify-content-lg-end">
                          <CDropdown color="primary" stoggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                            <div slot="toggler-content">
                              <span class="d-flex align-items-center">
                                {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                              </span>
                            </div>

                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="openSidebarRight('team_profile', { team_id: props.row.team_id })">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fas fa-users"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('common.Show_profile')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                            <CDropdownItem v-if="checkPermission('core.organization.teams')">
                              <div class="d-flex align-items-center" @click="openSidebarRight('team', { team_id: props.row.team_id, department_id: props.row.department_id, department_name: props.row.department_name })">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fas fa-pen"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('organisation.Edit_team')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                          </CDropdown>
                        </div>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <div class="text-center">
                        <span>{{$t('organisation.No_teams_found')}}</span>
                      </div>                
                    </template> 
                  </b-table>
                </div>          
              </CCol>
            </CRow>
            <CRow v-else class="m-0">
              <CCol cols="12" lg="12">
                <span>{{$t('organisation.Select_department_for_teams')}}</span>
              </CCol>
            </CRow>          
          </div>
        </CCol>        

        <CCol :cols="!employeeFocus ? 4 : 12" :lg="!employeeFocus ? 4 : 12" class="p-0">
          <div>
            <CRow class="m-0 actions">
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                <span v-if="activeTeam.team_id" v-line-clamp="1" class="text-uppercase">
                  <b>{{activeTeam.team_name}}</b>
                </span>
                <span v-else class="text-uppercase"><b>{{$t('common.Employees')}}</b></span>                    
              </CCol>
              <!-- <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                <div v-if="checkPermission('core.organization.employees') && activeTeam.team_id" class="mt-1 mt-xl-0 d-inline-block align-top">
                  <CButton class="m-0"
                           color="primary"
                           :disabled="!seatsAvailable"
                           @click="openSidebarRight('user', { user_id_external: null, team: {team_id: activeTeam.team_id, team_name: activeTeam.team_name + ' (' + activeDepartment.department_name + ')'} })">
                    <i class="fas fa-plus mr-1"/>
                    <span>{{ $t('users.Add_user') }}</span>
                  </CButton>                    
                </div>
              </CCol> -->
            </CRow>
            <CRow v-if="activeTeam.team_id">
              <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !employeesLoading}">
                <div v-if="employeesLoading">
                  <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                </div>
                <div v-else>
                  <b-table ref="employeesTable"
                          class="data_table includes_dropdown"
                          :data="employees"
                          :striped="true"
                          :hoverable="true"
                          :mobile-cards="true"
                          :paginated="employeesPaginated"
                          :per-page="employeesPerPage"
                          :current-page.sync="employeesPage"
                          :pagination-simple="isPaginationSimple"
                          :pagination-position="paginationPosition"
                          :default-sort-direction="defaultSortDirection"
                          :sort-icon="sortIcon"
                          :sort-icon-size="sortIconSize">
                        
                        <template slot-scope="props">
                          <b-table-column field="image" label="" :searchable="false" :sortable="false" width="2.5%">
                            <div v-if="props.row.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                            <div v-else class="profile_icon">
                              <userProfileAvatar :username="props.row.fullname" :size="25"/>
                            </div>
                          </b-table-column>                          
                          <b-table-column field="fullname" :label="$t('common.Name')" :searchable="searchEnabled" :sortable="true">
                            <span class="pointer" @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external })">
                              <b>{{props.row.fullname}}</b>
                            </span>                                                                                
                            <div class="item_tags d-flex flex-row">
                              <b-tag v-if="props.row.platform_role_tags.includes('admin')" class="mt-1 fit-content detail">{{ $t('common.Admin') }}</b-tag>
                              <b-tag v-if="props.row.left_organization" class="mt-1 fit-content left_organization">{{ $t('common.Left_organization') }}</b-tag>
                              <b-tag v-if="!props.row.is_active" class="mt-1 fit-content inactive">{{ $t('common.Inactive') }}</b-tag>
                            </div>                               
                          </b-table-column>
                          <b-table-column field="user_function" :label="$t('common.Function')" :searchable="searchEnabled" :sortable="true">
                            {{props.row.user_function !== null ? props.row.user_function : '-' }}
                          </b-table-column>
                          <b-table-column field="date_started" :visible="employeeFocus" :label="$t('common.Date_started')" :sortable="true">
                            {{props.row.date_started | moment("DD-MM-YYYY")}}
                          </b-table-column>
                          <b-table-column field="date_left" :visible="employeeFocus && showUnemployed" :label="$t('common.Date_left')" :sortable="true">
                            <span v-if="props.row.date_left !== null">{{props.row.date_left | moment("DD-MM-YYYY")}}</span>
                            <span v-else>-</span>
                          </b-table-column>
                          <b-table-column field="work_location" :visible="employeeFocus" :label="$t('users.Work_location')" :searchable="searchEnabled" :sortable="true">
                            {{props.row.work_location !== null ? props.row.work_location : '-' }}
                          </b-table-column>
                          <b-table-column field="date_started" :visible="employeeFocus" :label="$t('common.Last_activity')" :sortable="true">
                            <span v-if="props.row.last_app_activity !== null">{{props.row.last_app_activity | moment("DD-MM-YYYY HH:ss")}}</span>
                            <span v-else>-</span>                    
                          </b-table-column>
                          <b-table-column field="actions">
                            <div class="d-flex justify-content-lg-end">
                              <CDropdown color="primary" stoggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                                <div slot="toggler-content">
                                  <span class="d-flex align-items-center">
                                    {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                                  </span>
                                </div>
                           
                                <CDropdownItem v-if="checkRole('super_admin') && checkDateLeft(props.row.date_left)" class="admin">
                                  <div class="d-flex align-items-center" @click="openSidebarRight('reset_employee_password', { user_id_external:  props.row.user_id_external, employee_name: props.row.fullname })">
                                    <div class="post_action_icon text-center mr-1">
                                      <i class="fas fa-lock"/>
                                    </div>
                                    <div class="flex-grow-1">
                                      <span>{{$t('admin.Reset_employee_password')}}</span>
                                    </div>
                                  </div>
                                </CDropdownItem>
                                <CDropdownItem v-if="props.row.is_active">
                                  <div class="d-flex align-items-center" @click="deactivateUser(props.row.user_id_external)">
                                    <div class="post_action_icon text-center mr-1">
                                      <i class="fa-solid fa-ban"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                      <span>{{$t('organisation.Deactivate_user')}}</span>
                                    </div>
                                  </div>
                                </CDropdownItem>
                                <CDropdownItem v-if="props.row.is_active">
                                  <div class="d-flex align-items-center" @click="employeeLeftModal = true; employeeLeftModalData = props.row;">
                                    <div class="post_action_icon text-center mr-1">
                                      <i class="fa-solid fa-door-open"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                      <span>{{$t('users.Employee_left_organization')}}</span>
                                    </div>
                                  </div>
                                </CDropdownItem>                                                               
                                <CDropdownItem>
                                  <div class="d-flex align-items-center" @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external })">
                                    <div class="post_action_icon text-center mr-1">
                                      <i class="fas fa-user"/>
                                    </div>
                                    <div class="flex-grow-1">
                                      <span>{{$t('common.Show_profile')}}</span>
                                    </div>
                                  </div>
                                </CDropdownItem>
                                <CDropdownItem v-if="checkPermission('core.organization.employees')">
                                  <div class="d-flex align-items-center" @click="openSidebarRight('user', { user_id_external: props.row.user_id_external })">
                                    <div class="post_action_icon text-center mr-1">
                                      <i class="fas fa-pen"/>
                                    </div>
                                    <div class="flex-grow-1">
                                      <span>{{$t('users.Edit_employee_details')}}</span>
                                    </div>
                                  </div>
                                </CDropdownItem>                                                                
                              </CDropdown>
                            </div>
                          </b-table-column>
                        </template>
                        <template slot="empty">
                          <div class="text-center">                                                          
                            <span>{{$t('users.No_users_found')}}</span>             
                          </div>                
                        </template> 
                  </b-table>
                </div>
              </CCol>
            </CRow>
            <CRow v-else class="m-0">
              <CCol cols="12" lg="12">
                <span>{{$t('organisation.Select_team_for_employees')}}</span>
              </CCol>
            </CRow>           
          </div>
        </CCol>
      </CRow>
    </div>

    <b-modal :can-cancel="['x']" :active.sync="passwordResetLinkModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('organisation.Reset_password')}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="m-0">{{ $t('organisation.Are_you_sure_to_send_reset_link') }} {{passwordResetLinkModalData.fullname}}?</p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="sendPasswordResetLink(passwordResetLinkModalData.user_id_external); passwordResetLinkModal = false;"><i class="fas fa-check mr-1"/>{{$t('organisation.Send_reset_link')}}</CButton>
          <CButton color="secondary" @click="passwordResetLinkModal = false;"><i class="fas fa-times mr-1"/>{{$t('organisation.Dont_send_reset_link')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal :can-cancel="['x']" :active.sync="verificationEmailModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('organisation.Send_welcome_email')}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="m-0">{{ $t('organisation.Are_you_sure_to_send_welcome_email') }} {{verificationEmailModalData.fullname}}?</p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="sendVerificationEmail(verificationEmailModalData.user_id_external); verificationEmailModal = false;"><i class="fas fa-check mr-1"/>{{$t('organisation.Send_welcome_email')}}</CButton>
          <CButton color="secondary" @click="verificationEmailModal = false;"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal :can-cancel="['x']" :active.sync="employeeLeftModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('users.Set_date_left_for_employee', { employee_name : employeeLeftModalData.name})}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="mb-2_5">{{$t('users.Select_date_left_for_employee')}}</p>
              <b-datepicker v-model="employeeDateLeft" :placeholder="$t('common.click_to_select')" inline editable :datepicker="datepickerOptions">
                <template #left>
                  <CButton color="primary" @click="employeeDateLeft = new Date();"><i class="fas fa-clock mr-1"/>{{$t('Now')}}</CButton>
                </template>
                <template #right>
                  <CButton color="secondary" @click="employeeDateLeft = null;"><i class="fas fa-times mr-1"/>{{$t('Clear')}}</CButton>
                </template>                    
              </b-datepicker>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="updateUserDateLeft(employeeLeftModalData);" :disabled="!employeeDateLeft">
            <i class="fas fa-check mr-1"/>{{$t('Save')}}
          </CButton>
          <CButton color="secondary" @click="employeeLeftModal = false">
            <i class="fas fa-times mr-1"/>{{ $t('cancel') }}
          </CButton>
        </CCardFooter>
      </CCard>
    </b-modal>    
  </div>  
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'Organisation',
  props: ['platformRoles', 'platformPermissions', 'seatsAvailable'],
  components: {
    Multiselect,
    QuickEdit,
    loadingSpinner,
    userProfilePopover,
    userProfileAvatar
  },
  data() {
    return {  
      apiBaseUrl: null,
      clientToken: null,          
      employeeFocus: false,
      activeDepartment: {
        department_name: null,
        department_id: null        
      },
      activeTeam: {
        team_name: null,
        team_id: null
      },
      departments: [],
      departmentsLoading: false,
      departmentsLoaded: false,
      departmentsPaginated: false,
      departmentsPerPage: 8,
      departmentsPage: 1,
      teams: [],
      teamsLoading: false,
      teamsPaginated: false,
      teamsPerPage: 8,
      teamsPage: 1,
      employees: [],
      employeesLoading: false,
      employeesPaginated: false,
      employeesPerPage: 8,
      employeesPage: 1,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showUnemployed: false,
      searchEnabled: true,
      passwordResetLinkModal: false,
      passwordResetLinkModalData: {},
      verificationEmailModal: false,
      verificationEmailModalData: {},
      employeeLeftModal: false,
      employeeLeftModalData: {},
      employeeDateLeft: null,
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      }
    }
  },
  methods: {
    filterOrganisationData() {
      this.getDepartments();
      
      if(this.activeDepartment.department_name && this.activeDepartment.department_id) {
        this.getDepartmentTeams(this.activeDepartment.department_name, this.activeDepartment.department_id);
      }

      if(this.activeTeam.team_name && this.activeTeam.team_id) {
        this.getTeamEmployees(this.activeTeam.team_name, this.activeTeam.team_id);
      }
    },
    getDepartments() {
      // Start the loader
      if(!this.departmentsLoaded) this.departmentsLoading = true;
      // Get the departments
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departments/overview')
      .then(res => {  
        this.departments = res.data.data;
        // Check if the pagination should be enabled
        (this.departments.length > this.departmentsPerPage) ? this.departmentsPaginated = true : this.departmentsPaginated = false;
        // Stop the loader
        this.departmentsLoading = false;
        // Update the departmentsLoaded value
        this.departmentsLoaded = true;         
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getDepartmentTeams(departmentName, departmentId) {
      // Check if a different department is selected
      if(this.activeDepartment.department_id !== departmentId) {
        // Start the loader
        this.teamsLoading = true;      
        // Clear the teams (if necessary) and employees            
        this.teams = [];
        this.employees = [];
        // Update the active data
        this.activeDepartment = { department_name: departmentName, department_id: departmentId };
        this.activeTeam = { team_name: null, team_id: null };
      }
      // Get the teams
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/department/' + departmentId + '/teams')
      .then(res => {
        this.teams = res.data.data;
        // Enable the pagination if necessary
        (this.teams.length > this.teamsPerPage) ? this.teamsPaginated = true : this.teamsPaginated = false;        
        // Stop the loader
        this.teamsLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getTeamEmployees(teamName, teamId) {
      // Check if a different team is selected
      if(this.activeTeam.team_id !== teamId) {
        // Start the loader
        this.employeesLoading = true;
        // Clear the employees
        this.employees = [];
        // Update the active data
        this.activeTeam = { team_name: teamName, team_id: teamId };
      }
      // Get the employees
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/team/' + teamId + '/employees')
      .then(res => {
        this.employees = res.data.data;
        // Filter on active status if necessary
        if(!this.showUnemployed) this.employees = this.employees.filter( i => i.left_organization === false );
        // Enable the pagination if necessary
        (this.employees.length > this.employeesPerPage) ? this.employeesPaginated = true : this.employeesPaginated = false;
        // Stop the loader
        this.employeesLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    showPasswordResetLinkModal(data) {
      this.passwordResetLinkModal = true;
      this.passwordResetLinkModalData = data;
    },    
    sendPasswordResetLink(userIdExternal) {
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/resetpassword/user/' + userIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.Password_reset_link_sent'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    showVerificationEmailModal(data) {
      this.verificationEmailModal = true;
      this.verificationEmailModalData = data;
    },
    sendVerificationEmail(userIdExternal) {
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/verifiction/email/user/' + userIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('organisation.Welcome_email_sent'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    getUserDetails(userIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/' + userIdExternal)
      .then(res => {
        let updatedUser = res.data.data;

        if(this.$refs !== undefined && this.$refs.employeesTable !== undefined ) {
          // Get the index of the updated employee
          let employeeIndex = this.$refs.employeesTable.data.findIndex(x => x.user_id_external == updatedUser.user_id_external);
          // Update the user
          this.$refs.employeesTable.data[employeeIndex].platform_roles = updatedUser.platform_roles;
          this.$refs.employeesTable.data[employeeIndex].platform_role_tags = updatedUser.platform_role_tags;
          this.$refs.employeesTable.data[employeeIndex].fullname = updatedUser.fullname;
          this.$refs.employeesTable.data[employeeIndex].team_name = updatedUser.team_name;
          this.$refs.employeesTable.data[employeeIndex].employee_name = updatedUser.employee_name;
          this.$refs.employeesTable.data[employeeIndex].user_function = updatedUser.user_function;
          this.$refs.employeesTable.data[employeeIndex].date_started = updatedUser.date_started;
          this.$refs.employeesTable.data[employeeIndex].date_left = updatedUser.date_left;
          this.$refs.employeesTable.data[employeeIndex].managers = updatedUser.managers;
          this.$refs.employeesTable.data[employeeIndex].active = updatedUser.active;
          this.$refs.employeesTable.data[employeeIndex].is_active = updatedUser.is_active;
          this.$refs.employeesTable.data[employeeIndex].hours_on_contract = updatedUser.hours_on_contract;
          this.$refs.employeesTable.data[employeeIndex].meyer_briggs = updatedUser.meyer_briggs;
          this.$refs.employeesTable.data[employeeIndex].disc = updatedUser.disc;
          this.$refs.employeesTable.data[employeeIndex].office_based = updatedUser.office_based;
          this.$refs.employeesTable.data[employeeIndex].division = updatedUser.division;
          this.$refs.employeesTable.data[employeeIndex].language = updatedUser.language;
          this.$refs.employeesTable.data[employeeIndex].persona_1 = updatedUser.persona_1;
          this.$refs.employeesTable.data[employeeIndex].persona_2 = updatedUser.persona_2;
          this.$refs.employeesTable.data[employeeIndex].persona_3 = updatedUser.persona_3;
          this.$refs.employeesTable.data[employeeIndex].work_location = updatedUser.work_location;
        }       
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    deactivateUser(userIdExternal) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/user/' + userIdExternal + '/deactivate')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.User_deactivated'), type: 'is-success', duration: 2000 });
        // Update the team employees
        this.getTeamEmployees(this.activeTeam.team_name, this.activeTeam.team_id);
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    updateUserDateLeft(userData) {
      let params = {};
      params.date_left = this.$luxon(this.employeeDateLeft.toISOString(), "yyyy-MM-dd");

      axios.put(process.env.VUE_APP_API_URL + '/v1/core/user/' + userData.user_id_external + '/left-company', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.User_updated'), type: 'is-success', duration: 2000 });
        // Update the team employees
        this.getTeamEmployees(userData.team_name, userData.team_id);
        // Close the modal
        this.employeeLeftModal = false;
        // Reset the employeeDateLeft value
        this.employeeDateLeft = null;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    showImportPage() {
      this.$router.push({path: '/core/user-management/import'});
    },
    exportUsers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/users/export', {responseType: 'blob'})
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.Export_succesful'), type: 'is-success', duration: 2000 });
        if (!window.navigator.msSaveOrOpenBlob){
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'employees-export.csv');
          document.body.appendChild(link);
          link.click();
        }else{
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob(new Blob([res.data]),"employees-export.csv");
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },        
    checkDateLeft(dateLeft) {      
      if(!dateLeft || (dateLeft && (Date.parse(new Date()) <= Date.parse(dateLeft)))) {
        return true;
      } else{
        return false;
      }
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },         
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.getDepartments();

    this.$bus.$on('update_departments', () => {
      this.getDepartments();
    });

    this.$bus.$on('update_department_teams', (team) => {
      this.getDepartmentTeams(team.department_name, team.department_id);
    });
    
    this.$bus.$on('update_team_employees', (user) => {
      this.getTeamEmployees(user.team_name, user.team_id);
    });    

    this.$bus.$on('update_user_details', (userIdExternal) => {
      this.getUserDetails(userIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_departments');
    this.$bus.$off('update_department_teams');
    this.$bus.$off('update_team_employees');
    this.$bus.$off('update_user_details');
  } 
}
</script>